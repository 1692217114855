import { ThemeProvider } from '@mui/material';
import './App.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import Router from './routes';
import theme from "./theme";
import GlobalStyles from './theme/GlobalStyles';
import React, { useEffect } from 'react';
import { initGA, logPageView } from './GoogleAnalytics';

function App() {
  useEffect(() => {
    initGA(); // Initialize Google Analytics
    logPageView(); // Log the initial page view
  }, []);
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <Router />
    </ThemeProvider>
  );
}

export default App;

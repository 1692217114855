import { colors, createTheme } from "@mui/material";
import shadows from "./shadows";
import typography from "./typography";

const theme = createTheme({
  palette: {
    primary: {
      main: "#1F2229",
    },
    secondary: {
      main: colors.indigo[500],
    },
    text: {
      primary: colors.blueGrey[900],
      secondary: colors.blueGrey[600],
    },
  },
  typography,
  shadows,
  components: {
    MuiAutocomplete: {
      styleOverrides: {
        tag: {
          margin: 1
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          backgroundColor: '#fff',
          ".MuiOutlinedInput-notchedOutline": {
            border: "2px solid #000"
          }
        }
      }
    },
    MuiCard: {
      styleOverrides: {
        root: {
          border: '2px solid #000',
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '0'
        }
      }
    },
  }
});

export default theme;

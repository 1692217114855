const customTypography = {
    fontFamily: "Poppins",
    h1: {
    },
    h2: {
        color: '#fff',
        fontSize: '48px',
        fontWeight: "700"
    },
    h3: {
        fontSize: '32px',
        fontWeight: '700'
    },
    h4: {
    },
    h5: {
    },
    h6: {
    },
    subtitle1: {
        fontSize: '21px',
        fontWeight: '400'
    },
    subtitle2: {
        fontWeight: '400',
        fontSize: '15px'
    },
    caption1: {
    },
    caption2: {
    },
    overline: {
    },
    button: {
        textTransform: 'capitalize'
    }
};

export default customTypography;

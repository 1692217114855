import { lazy, Suspense } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';

const AboutPage = lazy(() => import('../pages/about'));
const CircularIdeasPage = lazy(() => import('../pages/circularIdeas'));
const HomePage = lazy(() => import('../pages/home'));
//

// ----------------------------------------------------------------------

export default function Router() {
    return useRoutes([
        {
            path: 'about',
            element: <Suspense fallback={<></>}><AnimatePresence wait initial={false}><AboutPage /></AnimatePresence></Suspense>,
        },
        {
            path: 'circular-ideas',
            element: <Suspense fallback={<></>}><AnimatePresence wait initial={false}><CircularIdeasPage /></AnimatePresence></Suspense>,
        },
        {
            path: '/',
            element: <Suspense fallback={<></>}><AnimatePresence wait initial={false}><HomePage /></AnimatePresence></Suspense>,
        },
        {
            path: '*',
            element: <Suspense fallback={<></>}><AnimatePresence wait initial={false}><Navigate to="/404" replace /></AnimatePresence></Suspense>,
        },
    ]);
}

import ReactGA from 'react-ga';

export const initGA = () => {
  ReactGA.initialize('G-V2X2MVE8XF'); // Replace with your own tracking ID
};

export const logPageView = () => {
  ReactGA.set({ page: window.location.pathname });
  ReactGA.pageview(window.location.pathname);
};

export const logEvent = (category, action, label) => {
  ReactGA.event({ category, action, label });
};

export const logException = (description, fatal = false) => {
  ReactGA.exception({ description, fatal });
};
